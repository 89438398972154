<template lang="pug">
svg(
  v-if='shape === "car"',
  width='21',
  height='16',
  fill='none',
  xmlns='http://www.w3.org/2000/svg'
)
  path(
    d='M17.85 5.333h-.336l-1.313-3.328A3.19 3.19 0 0015.044.55 3.115 3.115 0 0013.283 0h-6.3A3.12 3.12 0 004.98.722 3.21 3.21 0 003.885 2.57l-.546 2.762H3.15c-.835 0-1.637.337-2.227.938C.332 6.87 0 7.685 0 8.533v3.2c0 .283.11.554.308.755.196.2.464.312.742.312H2.1c0 .849.332 1.663.923 2.263.59.6 1.392.937 2.227.937s1.637-.337 2.227-.937c.591-.6.923-1.414.923-2.263h4.2c0 .849.332 1.663.923 2.263.59.6 1.392.937 2.227.937s1.637-.337 2.227-.937c.591-.6.923-1.414.923-2.263h1.05c.279 0 .546-.112.742-.312.197-.2.308-.472.308-.755v-3.2c0-.848-.332-1.662-.923-2.262a3.125 3.125 0 00-2.227-.938zm-6.3-3.2h1.732c.209.002.412.067.584.187s.305.289.383.485l.997 2.528H11.55v-3.2zm-5.607.854a1.07 1.07 0 01.373-.62 1.04 1.04 0 01.677-.234H9.45v3.2H5.481l.462-2.346zm-.693 10.88c-.208 0-.41-.063-.583-.18a1.063 1.063 0 01-.387-.479 1.082 1.082 0 01.228-1.162 1.035 1.035 0 011.144-.231c.192.08.356.217.471.392a1.079 1.079 0 01-.13 1.347c-.197.2-.465.313-.743.313zm10.5 0c-.208 0-.41-.063-.583-.18a1.064 1.064 0 01-.387-.479 1.083 1.083 0 01.228-1.162 1.035 1.035 0 011.144-.231c.192.08.356.217.471.392a1.078 1.078 0 01-.13 1.347c-.197.2-.464.313-.743.313zm3.15-3.2h-.819a3.151 3.151 0 00-1.056-.774 3.108 3.108 0 00-3.606.774H7.581a3.151 3.151 0 00-1.057-.774 3.108 3.108 0 00-3.605.774H2.1V8.533c0-.283.11-.554.308-.754.196-.2.464-.312.742-.312h14.7c.278 0 .545.112.742.312.197.2.308.471.308.754v2.134z',
    fill='#5477A9'
  )
svg(
  v-else,
  width='14',
  height='16',
  fill='none',
  xmlns='http://www.w3.org/2000/svg'
)
  path(
    d='M13 15v-1.555a3.17 3.17 0 00-.879-2.2A2.947 2.947 0 0010 10.334H4c-.796 0-1.559.327-2.121.91a3.17 3.17 0 00-.879 2.2V15M7 7.222c1.657 0 3-1.393 3-3.11C10 2.391 8.657 1 7 1S4 2.393 4 4.111C4 5.83 5.343 7.222 7 7.222z',
    stroke='#5477A9',
    stroke-width='2',
    stroke-linecap='round',
    stroke-linejoin='round'
  )
</template>
<script>
export default {
  props: ['shape'],
}
</script>